#Home{
}

#Detail{
    width: 100%;
}

.Detail{
    display: inline-block;
    width: 200px;
    margin: 25px 50px 0px;
    vertical-align: top;
}

.DetailImg{
    text-align: center;
}

.DetailText{
    text-align: center;
}

.DetailText a{
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.Detail_mobile{
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.DetailImg_mobile{
    display: inline-block;
    vertical-align: text-bottom;
    margin-top: 40px;
    width: 100%;
}

.DetailText_mobile{
    text-align: center;
    vertical-align: middle;
    margin-right: 25px;
}

.DetailImg_mobile img{
    text-align: left;
}

.DetailImg_mobile h3{
    display: inline-block;
    float: right;
    margin-right: 30px;
}

.DetailImg_mobile a{
    font-size: 17px;
    text-decoration: none;
    color: black;
    font-weight: bold;
}

.DetailText_mobile p{
    font-size: 15px;
    margin-left: 50px;
}

#NetworkingDetail{
    
}

#EMRSystemDetail{
    
}

#Intro{
    vertical-align: top;
    margin: 0 auto;
    position: relative;
    height: 570px;
}

#Intro img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    vertical-align: top;
}

#Intro_mobile{
    vertical-align: top;
    margin: 0 auto;
    position: relative;
}

#Intro_mobile img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#IntroText{
    z-index: 1;
    right: 0;
    bottom: 0;
    position: absolute;
}

#IntroText_mobile{
    text-align: center;
}
