
h1{
    font-size: 35px;
    font-weight: bold;
}

h2{
    font-size: 26px;
    font-weight: bold;
}

h3{
    font-size: 20px;
    font-weight: bold;
}

a{
    font-size: 18px;
    font-weight: normal;
}

p{
    font-size: 18px;
    font-weight: normal;
}

#MainDiv{
    background-color: #d4d6d7;
}

#NavBar{
    text-align:center;
    overflow: auto;
}

#NavBar-ul {
    list-style-type: none;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0;
    overflow: auto; 
    display: inline-block;
}

.NavBar-ul {
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 0px;
    padding: 0;
    overflow: auto; 
    display: inline-block;
}

.NavBar-li {
    float: left;
    background-color: #62666a;
}

.NavBar-li a {
    display: block;
    color: white;
    text-align: center;
    width: 300px;
    text-decoration: none;
}

.NavBar-li-mobile {
    float: left;
    background-color: #62666a;
}

.NavBar-li-mobile a {
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    width: 150px;
    font-size: 10px;
    font-weight: normal;

}

#NavBar li a:hover {
    background-color: #5e768f;
}
 
.NavBarActive {
    background-color: #5e768f;
}

.CompanyInfo{
    background-color: #c0c3c5;
    margin-top: 20px;
    margin-bottom: 0;
    text-align: center;
    overflow: hidden;
    display: flex;            
    display: -webkit-flex;            
    justify-content: center;            
    align-items: center;
    position: relative;
}

.CompanyInfoContent{
    width: 1016px;
    overflow: hidden;
    text-align: middle;
}

#MJX_Title{
    display:inline-block;
    vertical-align: text-bottom;
    float: right;
}

#MJX_Title_mobile{
    display:inline-block;
    vertical-align: text-bottom;
    float: right;
}

.MJX_TitleText{
    margin: 20px 250px;
}

#MJX_Img{
    vertical-align: text-bottom;
}

#MJX_Img_mobile{
    vertical-align: text-bottom;
    float: left;
    width: 40%;
}

#SiteFoot{
    
}

#SiteFoot ul{
    list-style-type: none;
    width: 220px;
    display: inline-block;
    vertical-align: top;
    margin-right:50px;
    margin-left: 0;
    float: left;
}

#SiteFoot li{
    text-align: left;
    border-bottom: medium solid white;
}

#SiteFoot li a{
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 14px;
}

#SiteFoot li a:hover{
    color: #FF7F50;
}

#SiteFoot_mobile ul{
    list-style-type: none;
    vertical-align: top;
    float: left;
    display: inline-block;
}

#SiteFoot_mobile li{
    text-align: left;
    border-bottom: medium solid white;
}

#SiteFoot_mobile li a{
    display: block;
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    font-size: 10px;
}

#SiteFoot_mobile li a:hover{
    color: #FF7F50;
}

#PageContent{
    overflow: auto;
    display: flex;            
    display: -webkit-flex;
    justify-content: center;            
    align-items: center;
}

.PageContent{
    width: 1016px; 
}

.NavBar-li:hover .NavBar-DropDown-Content{
    height: 100%;
}

.NavBar-DropDown-Content{
    height: 0px;
    overflow: hidden;
    transition: .5s;
    background-color: transparent;
    padding: 0;
    position: absolute;
}

.NavBar-DropDown-Content li{
    text-align: center;
    list-style-type: none;
    background-color: #62666a;
}

#NavBar-ul:after{
  content: ''; 
  display: block; 
  clear: both; 
}

.MobileText{
    margin-left: 25px;
    margin-right: 15px;
}

.MobileAlignCenter{
    text-align: center;
}

#MJX_Title_mobile h1{
    font-size: 20px;
}

#MJX_Title_mobile h3{
    font-size: 15px;
}
